.wrapper{
  position: relative;
  top: 0;
  border: solid 1px transparent;
  color: #000;
  min-height: 500px;
}

.wrapper h4{
  margin-bottom: 0px;
}

.wrapper ul{
  margin-top: 10px;
}

.pool{
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  padding-right: 40px;
}

.boxOne{
  position: absolute;
  left: 0;
  top: 0;
}

.boxTwo{
  position: absolute;
  right: 0;
  top: 0;
  width: 26%;
}

.boxThree{
  position: absolute;
  left: 0;
  bottom: 0;
}

.boxFour{
  position: absolute;
  right: 0;
  bottom: 0;
  width: 26%;
}

.heading{
  display: none;
}

@media(max-width: 768px) {
  .animation {
    display: none;
  }

  .boxOne, .boxTwo, .boxThree, .boxFour{
    position: initial;
    width: 100%;
    font-size: 1rem !important;
  }

  .heading{
    display: block;
  }
}