.header{
  display: flex;
  justify-content: flex-end;
  /*position: sticky;*/
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  padding: 20px;
  background-color: #27256d;
  height: 65px;
  z-index: 1000;
}

.headerHovering{
  box-shadow: 0 0px 32px 0 rgba(0, 0, 0, 0.5);
}

.logoContainer{

}

.logoHover {
  display: block;
  height: 65px;
  transition: height 1s;
}

.logo{
  display: block;
  height: 80px;
  transition: height 1s;
}

.toggleMenu{
  margin-left: 40px !important;
  margin-top: -5px;
}

.toggleMenu img{
  width: 60px;
}

.menu{
  margin-right: 20px;
  margin-top: 10px;
  background-color: #27256d;
}

.menuItem{
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: "Red Hat Text", sans-serif;
  font-style: normal;
  font-weight: 700;
  /*input has OS specific font-family*/
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  font-size: 1rem;
  min-height: 50px;
  line-height: 45px;
  vertical-align: middle;
  margin-right: 16px;
  transition: 0.2s font-size;
  border-bottom: solid 5px rgba(1,1,1,0);
}

/* For chrome */
.menuItem:focus {
  outline:0;
}

.currentMenuItem{
  border-bottom: solid 5px #f05a73;
}

.menuItem:hover{
  font-size: 1.2rem;
  pointer: cursor;
}

@media(max-width: 768px) {
  .logo {
    height: 45px;
  }

  .logoHover {
    height: 45px;
  }

  .header{
    justify-content: space-between;
    height: 55px;
    padding-bottom: 10px;
  }

  .menu{
    transition: 0.2s height;
    height: 0;
    overflow: hidden;
    position: fixed;
    top: 75px;
    left: 0px;
    z-index: 2000;
    width: 100%;
    box-shadow: 0 16px 16px 0 rgba(0, 0, 0, 0.5);
  }
  .menuItem{
    display: block;
    text-align: left;
    margin-left: 20px;
  }
  .menuVisible{
    display: inline-block;
    height: 300px;
  }
}

@media(min-width: 768px) {
  .toggleMenu{
    display: none;
  }
}