.page{
  padding: 0px 50px 40px 60px;
  position: relative;
}

.anchor{
  position: absolute;
  top: -70px;
}

@media(max-width: 768px){
  .page{
    padding: 0px 20px 20px 20px;
    position: relative;
  }

  .anchor{
    top: -40px;
  }
}