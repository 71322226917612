.wrapper{
  display: flex;
}

.stairs{
  display: inline-block;
  position: relative;
  top: 20px;
}

.stairsImage{
  display: block;
  position: relative;
}

.slogan{
  white-space: nowrap;
  display: inline-block;
  font-size: 2.5rem;
  vertical-align: top;
  font-weight: 700;
  margin: 1% 0px 0px 50px;
}

.intro{
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.introText{
  white-space: pre-wrap;
  padding-right: 1rem;
  /*max-width: 600px;*/
}

.appLinks{
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

.appLinks img{
  display: inline-block;
  height: 40px;
  margin-right: 10px;
}

.appLinks a:last-of-type img{
  margin-right: 0px !important;
}

@media(max-width: 756px) {
  .slogan {
    margin-top: -50px;
  }
}

@media(max-width: 756px) {
  .wrapper {
    display: block;
  }

  .stairs {
    display: block;
    margin-left: 50%;
    transform: translateX(-50%);
    top: -50px;
  }

  .slogan {
    text-align: center;
    display: block;
    margin: 0;
  }

  .winner {
    width: 100%;
    text-align: center;
  }

  .intro {
    margin-top: 1.5rem;
  }

  .introText {
    max-width: none !important;
  }

  .appLinks {
    width: 100%;
    justify-content: space-evenly;
  }

  .appLinks img {
    display: inline-block;
    height: 28px;
    margin-right: 8px;
  }
}
