.wrapper{
  color: #000;
}

.wrapper a{
  color: rgba(0, 0, 10, 1);
}

.wrapper a:hover{
  color: rgba(0, 0, 10, 0.75);
}

.wrapper q{
  font-style: italic;
  color: #000;
  font-weight: 500;
}

.story{
  max-width: 1024px;
  clear: both;
  margin-bottom: 3rem;
}

.story img{
  width: 25%;
  min-width: 256px;
  margin-right: 2rem;
  margin-bottom: 2rem;
  vertical-align: top;
  display: block;
  float: left;
  border: solid 2px #27256c;
}

@media(max-width: 768px) {
  .story img {
    margin-right: 1rem;
    margin-bottom: 1rem;
    float: none;
  }
}
