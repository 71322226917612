.popup{
  display: block;
  position: fixed;
  bottom: 0px;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(0, 0, 0, 0.9);
  color: #ddd;
  font-size: 0.7em;
  z-index: 10000;
  overflow: auto;
}

.popupClosed{
  display: none;
}

.content{
  padding: 0px 40px 0px 40px;
  max-width: 600px;
}

.popup a{
  color: rgba(255, 255, 255, 0.5);
}

.popup a:hover{
  color: rgba(255, 255, 255, 0.75);
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: flex-start;
}

.actions button{
  background-color: #27256c;
  border: none;
  color: white;
  padding: 12px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.actions button:hover{
  cursor: pointer;
  background-color: #39377e;
}

@media(max-width: 768px) {
  .content{
    padding: 0px 15px 0px 15px;
  }
}
