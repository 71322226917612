.wrapper{
  color: #000;
}

.wrapper p{
 max-width: 800px;
}

.illustration{
  float: right;
  width: 33%;
  margin-left: 2rem;
}


@media(max-width: 768px) {
  .illustration{
    display: none;
  }
}