.container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #f05a73;
  color: #fff;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  gap: 0.5rem;
}

.container button {
  height: 1.5rem;
  line-height: 1.5rem;
  margin: 0;
  font-size: 0.75rem;
  background-color: #27256c;
  border: none;
  color: white;
  padding: 0 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
}

.container button:hover {
  background-color: #39377e;
}
