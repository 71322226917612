.wrapper{
  padding: 10px;
  font-size: 0.7rem;
  color: rgba(255, 255, 255, 0.5);
  text-align: right;
}

.wrapper a{
  color: rgba(255, 255, 255, 0.5);
}

.wrapper a:hover{
  color: rgba(255, 255, 255, 0.75);
}

.wrapper span{
  padding: 0px 6px 0px 6px;
  border-right: solid 1px rgba(255, 255, 255, 0.5);
  display: inline-block;
}

.wrapper span:last-of-type{
  border-right: none;
  padding-right: 0px;
}

@media(max-width: 768px) {
  .wrapper{
    text-align: left;
  }

  .wrapper span{
    display: block;
    border-right: none;
  }
}
