html {
  font-size: 21px;
}

body {
  margin: 0;
  font-family: 'Red Hat Text', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #27256d;
  color: #fff;
  scroll-behavior: smooth;
}

h1{
  font-size: 1.8rem;
}

h1, h2, h3, h4 {
  font-weight: 500;
}

ul{
  margin-left: 0;
  padding-left: 22px;
}

@media(max-width: 768px){
  html {
    font-size: 18px;
  }
}

@media(max-width: 400px){
  html {
    font-size: 16px;
  }

  h1{
    font-size: 1.6rem;
  }
}