.wrapper{
  color: #000;
  background-color: #fff;
  padding: 40px 40px 0px 40px;
}

.wrapper img{
  vertical-align: middle;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.text{
  display: inline-block;
  padding-right: 2rem;
  position: relative;
  top: -1rem;
}

@media(max-width: 768px) {
  .text{
    display: block;
    margin-bottom: 1rem;
  }
}