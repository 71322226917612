.wrapper{
  color: #fff;
  display: flex;
  justify-content: space-between;
}

.content{
}

.animation{
  position: relative;
}

@media(max-width: 768px) {
  /*.animation{
    display: none;
  }*/

  .wrapper{
    flex-wrap: wrap;
  }
}