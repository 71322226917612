.wrapper{
}

.wrapper a{
 color: rgba(255, 255, 255, 0.5);
}

.wrapper a:hover{
  color: rgba(255, 255, 255, 0.75);
}

.case{
  max-width: 1024px;
  clear: both;
  margin-bottom: 3rem;
}

.case img{
  width: 25%;
  min-width: 256px;
  margin-right: 2rem;
  margin-bottom: 2rem;
  vertical-align: top;
  display: block;
  float: left;
  border: solid 2px #27256c;
}

@media(max-width: 768px) {
  .case img {
    margin-right: 1rem;
    margin-bottom: 1rem;
    float: none;
  }
}
